import {SALARY_ENDPOINT} from '../utils/endpoints';
import EntityEditor from './EntityEditor';

export default {
    mixins: [EntityEditor],
    watch: {
        'item.dynamic_amount': function (val) {
            if (!!val) {
                this.item.amount = 0;
            }
        },
        'item.workPosition': {
            handler: function (val) {
                if (!!val) {
                    this.item.work_position_id = val.id;
                }
            },
            deep: true,
        },
    },
    data() {
        return {
            item: {
                sign: 1,
                name: '',
                description: '',
                amount: 0,
                dynamic_amount: false,
                work_position_id: null,
                workPosition: null,
            },
        };
    },
    methods: {
        createUrl() {
            return SALARY_ENDPOINT + '/bounty/type';
        },
        updateUrl() {
            return SALARY_ENDPOINT + `/bounty/type/${this.item.id}`;
        },
        deleteUrl() {
            return this.updateUrl();
        }
    },
}
