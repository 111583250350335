export default {
    methods: {
        setDefaultLotCount(item, lot_index) {
            let lot = item.lots[lot_index];
            lot.count = this.getDefaultLotCount(item, lot_index);
        },
        getDefaultLotCount(item, lot_index) {
            let count = 0.0;
            let lot = item.lots[lot_index];
            let lotMaxCount = this.getMaxCount(lot);

            if (!!item.count) {
                let diff = parseFloat(item.count) - this.lotSum(item, lot_index);
                if (diff > 0 && lotMaxCount > 0) {
                    if (diff <= lotMaxCount) {
                        count = parseFloat(diff).toFixed(3);
                    } else {
                        count = parseFloat(lot.nomenclatureLot.count).toFixed(3);
                    }
                }
            }

            return count;
        },
        lotSum(item, excludeIndex = null) {
            let sum = 0.0;

            if (Array.isArray(item.lots)) {
                item.lots.map((lot, index) => {
                    if (!!excludeIndex && index === excludeIndex) {
                        return;
                    }

                    sum += !!lot.count ? parseFloat(lot.count) : 0.0;
                });
            }

            return parseFloat(sum.toFixed(3));
        },
        lotCountValid(lot) {
            if (!(!!lot.nomenclatureLot)) {
                return false;
            }

            if (this.storage.allow_lot_below_zero) {
                return true;
            }

            let maxCount = this.getMaxCount(lot);

            return parseFloat(parseFloat(lot.count).toFixed(3)) <= maxCount;
        },
        getMaxCount(lot) {
            let count = null;

            if (!!lot.nomenclatureLot && !!lot.nomenclatureLot.id) {
                let withCount = this.lots.filter(l => l.id === lot.nomenclatureLot.id);
                if (withCount.length) {
                    count = parseFloat(parseFloat(withCount[0].count).toFixed(3));
                }
            }

            return count;
        },
    }
}
