export default {
    methods: {
        parseErrors(response) {
            let result = [];
            console.log(response.data);
            if (!!response.data) {
                if (!!response.data.errors) {
                    Object.keys(response.data.errors).map(k => {
                        if (Array.isArray(response.data.errors[k])) {
                            response.data.errors[k].map(mess => {
                                result.push({
                                    propertyPath: k,
                                    message: mess
                                });
                            })
                        }
                    })
                } else if (!!response.data.message) {
                    result = [response.data.message]
                } else {
                    result = [response.status + ' Произошла ошибка']
                }
            } else {
                result = [response.status + ' Произошла ошибка']
            }

            return result;
        }
    }
}
