<template>
    <div class="card mb-0">
        <div class="card-body">
            <h3 class="text-center p-4">Перевыпуск на {{ overproducedPercent }}%. Проверьте цифры</h3>
            <button class="btn btn-lg btn-success pull-right m-2" @click="$emit('close')">Проверить</button>
            <button class="btn btn-lg btn-danger pull-left m-2" @click="continueCallback(); $emit('close')">Всё равно сохранить</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OverproductionConfirm",
        props: {
            overproducedPercent: {
                type: Number,
                required: true
            },
            continueCallback: {
                type: Function,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>
