import BarcodeRepository from '../utils/BarcodeRepository';
import { BOX_OBJECT_TYPE, NOMENCLATURE_LOT_OBJECT_TYPE } from "@utils/objectTypes";
import { NOMENCLATURE_LOTS_ENDPOINT, NOMENCLATURES_ENDPOINT } from "@utils/endpoints";

export default {
    methods: {
        getItemByBarcode(barcodeData, newItemCallback, existingItemCallback, currentItems = [], storageId = null, date = null) {
            BarcodeRepository.findFirstResourceByBarcode(barcodeData.barcode)
                .then(result => {
                    if (!result) {
                        return;
                    }

                    let item;

                    switch (result.object.objectType) {
                        case NOMENCLATURE_LOT_OBJECT_TYPE:
                            item = currentItems.find(item => item.nomenclatureLot.id === result.resource.id);

                            if (!item) {
                                if (!storageId) {
                                    newItemCallback({nomenclatureLot: result.resource});
                                    return;
                                }

                                this.getStorageReport(storageId, date, result.resource.id)
                                    .then(response => {
                                        if (response.data && response.data.length) {
                                            newItemCallback({
                                                nomenclatureLot: result.resource,
                                                count: response.data[0].count,
                                            });
                                        } else {
                                            newItemCallback({nomenclatureLot: result.resource});
                                        }
                                    });
                            } else {
                                existingItemCallback(item);
                            }
                            break;
                        case BOX_OBJECT_TYPE:
                            item = currentItems.find(item => item.box.name === result.resource.name);

                            if (!item) {
                                item = {box: barcodeData.resource};

                                this.getBoxContents(result.resource)
                                    .then(response => {
                                        if (response && response.data && response.data.length > 0) {
                                            item.count = response.data[0].count;

                                            if (response.data[0].nomenclatureLotId) {
                                                this.$http
                                                    .get(NOMENCLATURE_LOTS_ENDPOINT + '/' + response.data[0].nomenclatureLotId)
                                                    .then(response => response.data.data)
                                                    .then(nomenclatureLot => {
                                                        newItemCallback({...item, nomenclatureLot});
                                                    });
                                            } else if (response.data[0].nomenclatureId) {
                                                this.$http
                                                    .get(NOMENCLATURES_ENDPOINT + '/' + response.data[0].nomenclatureId)
                                                    .then(response => response.data.data)
                                                    .then(nomenclature => {
                                                        newItemCallback({...item, nomenclature});
                                                    });
                                            } else {
                                                newItemCallback(item);
                                            }
                                        } else {
                                            newItemCallback(item);
                                        }
                                    })
                            } else {
                                existingItemCallback(item);
                            }
                            break;
                    }
                })
        },
        getBoxContents(box, storageId = null, date = null) {
            if (box.id) {
                return this.$http.post('boxes/state', {
                    box_id: box.id,
                    storageId,
                    date: date
                });
            } else {
                return this.$http.post(`boxes/${box.name}`)
                    .then(response => {
                        if (response.data.id) {
                            return this.$http.post('boxes/state', {
                                box_id: response.data.id,
                                storageId,
                                date: date
                            });
                        }

                        return null;
                    });
            }
        }
    },
}
