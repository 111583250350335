import EntityEditor from './EntityEditor';
import {MATERIAL_TRANSFER_CORRECTION_ENDPOINT} from '../utils/endpoints';

export default {
    mixins: [EntityEditor],
    methods: {
        createUrl() {
            return MATERIAL_TRANSFER_CORRECTION_ENDPOINT
        },
    }
}
