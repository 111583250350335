export default {
    methods: {
        getStuff(tasks) {
            let stuff = {};

            tasks.map(t => {
                t.stuff.map(s => {
                    if (!!stuff[s.nomenclature_id]) {
                        stuff[s.nomenclature_id].count += parseFloat(s.count);
                    } else {
                        stuff[s.nomenclature_id] = {
                            nomenclature: s.nomenclature,
                            count: s.count,
                            nomenclature_id: s.nomenclature_id
                        };

                        if (!!s.on_stock) {
                            stuff[s.nomenclature_id].on_stock = s.on_stock;
                        }
                    }
                });
            });

            return stuff;
        }
    }
}
