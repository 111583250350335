import EntityEditor from './EntityEditor';
import {SALARY_ENDPOINT} from '../utils/endpoints';

export default {
    mixins: [EntityEditor],
    watch: {
        'item.actual_shift': function (val) {
            if (!!val && !!val.shift) {
                this.item.actual_shift_id = val.id
            } else {
                this.item.actual_shift_id = null
            }
        },
        'item.type': function (val) {
            if (!!val) {
                this.item.salary_bounty_type_id = val.id;
                if (!val.dynamic_amount) {
                    this.item.amount = val.amount;
                }
            }
        },
        'item.user': function (val) {
            if (!!val) {
                this.item.user_id = val.id;
            }
        }
    },
    data() {
        return {
            item: {
                user: null,
                user_id: null,
                actual_shift_id: null,
                actual_shift: null,
                salary_bounty_type_id: null,
                type: null,
                amount: 0,
                comment: '',
            },
        };
    },
    methods: {
        createUrl() {
            return SALARY_ENDPOINT + '/bounty';
        },
        updateUrl() {
            return SALARY_ENDPOINT + `/bounty/${this.item.id}`;
        },
        deleteUrl() {
            return this.updateUrl();
        }
    }
}
