import {cloneDeep} from "lodash";

export default {
    props: {
        index: {
            type: Number,
            required: false
        },
        value: {
            type: Object,
            required: false
        },
        successCallback: {
            type: Function,
            required: false,
        },
        with: {
            type: Array,
            required: false,
            default: () => []
        },
        appends: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    computed: {
        params() {
            return {
                with: this.with,
                appends: this.appends,
                without_loading: true,
            };
        },
    },
    data() {
        return {
            loading: false,
            item: {},
            errors: {}
        };
    },
    methods: {
        createUrl() {
            throw new Error('Not implemented');
        },
        updateUrl() {
            throw new Error('Not implemented');
        },
        deleteUrl() {
            throw new Error('Not implemented');
        },
        save() {
            if (!!this.item.id) {
                this.update();
            } else {
                this.create();
            }
        },
        create() {
            if (this.loading) {
                return;
            }
            this.startLoading();
            this.wrapPromise(this.$http.post(this.createUrl(), this.item, {params: this.params}));
        },
        update() {
            if (this.loading) {
                return;
            }
            this.startLoading();
            this.wrapPromise(this.$http.put(this.updateUrl(), this.item, {params: this.params}));
        },
        deleteItem() {
            if (this.loading) {
                return;
            }
            this.startLoading();
            this.wrapPromise(this.$http.delete(this.deleteUrl(), {params: this.params}));
        },
        startLoading() {
            this.loading = true;
            this.errors = {};
        },
        wrapPromise(promise) {
            promise.then(this.success).catch(this.catch).finally(this.finally);
        },
        success(response) {
            this.successCallback(response.data);
            this.$emit('close');
        },
        catch(response) {
            this.$root.responseError(response, 'Ошибка')
        },
        finally() {
            this.loading = false;
        },
        numberFormatter(value) {
            return parseFloat(parseFloat(value.toString().trim().replace(',','.').replace(/[^\d.-]/g,'')).toFixed(2));
        },
        errorMessageFormatter(message) {
            return message;
        }
    },
    mounted() {
        if (!!this.value) {
            this.item = cloneDeep(this.value);
        }
    }
}
