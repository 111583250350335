import {mapState} from 'vuex';
import stuffMixin from './stuffMixin';
import {
    SALARY_ENDPOINT,
    PRODUCTION_ACTS_ENDPOINT,
    PRODUCTION_SHIFT_ENDPOINT
} from "../utils/endpoints";
import moment from "moment";
import ErrorHandler from "../components/productiontask/StorageSeniorDashboard/ErrorHandler";
import OverproductionConfirm from "../components/productiontask/StorageSeniorDashboard/productionAct/OverproductionConfirm";
import {sumBy} from "lodash";

const ALLOWED_OVERPRODUCTION_PERCENT = 10;
const WITH = [
    'materialTransferActs.storageFrom',
    'materialTransferActs.items.nomenclatureLot.nomenclature.measureUnit',
];
export default {
    mixins: [stuffMixin],
    computed: {
        ...mapState({
            tasks: state => state.storage_senior_dashboard.selectedTasks,
            actualShift: state => state.storage_senior_dashboard.actualShift,
            storage: state => state.storage_senior_dashboard.storage,
        }),
        measureUnit() {
            return this.tasks[0].nomenclature.measure_unit.name;
        },
        requiredCount() {
            return parseFloat(sumBy(this.tasks, t => parseFloat(t.count)).toFixed(3));
        },
        producedCount() {
            return parseFloat(sumBy(this.tasks, t => sumBy(t.productionActs, a => sumBy(a.items, i => parseFloat(i.count)))).toFixed(3));
        },
        mixedCount() {
            return parseFloat(sumBy(this.tasks, t => sumBy(t.productionActs, a => sumBy(a.items, i => {
                    if (i.count > 0) {
                        return i.count;
                    }

                    return i.plan_count;
                }))).toFixed(3));
        },
        leftToProduce() {
            return parseFloat( (this.requiredCount - this.mixedCount).toFixed(3) );
        },
        overproducedCount() {
            return this.leftToProduce < 0 ? Math.abs(this.leftToProduce) : 0.0;
        },
        overproducedPercent() {
            return this.requiredCount > 0 ? Math.round(this.overproducedCount / this.requiredCount * 100) : 0;
        },
        stuff() {
            return this.getStuff(this.tasks);
        }
    },
    data() {
        return {
            relevantShift: null,
            productionActParams: {
                modal: {
                    scrollable: true,
                    adaptive: true,
                    height: 'auto',
                    width: 900,
                }
            },
            workingUsersLoading: false,
            workingUsers: [],
        };
    },
    methods: {
        createProductionAct(item = null, force = false) {
            let items = [];
            if (!!item) {
                items = [item];
            }

            if (this.assertOverProduction(items, force, () => this.createProductionAct(item, true))) {
                return;
            }

            if (this.assertNegativeItems(items)) {
                return;
            }

            let taskIds = [];
            let stuff = [];

            // Важный момент! Так мы сохраняем ссылку на нужную таску в замыкании запроса
            // Решает кейс, когда начали выпуск и тут же переключились на другую таску
            // - после завершения запроса акт подсовывался не туда
            let tasksLink = this.tasks;

            this.tasks.map(t => {
                t.ids.map(i => {
                    taskIds.push({id: i})
                });
            });

            Object.keys(this.stuff).map(k => {
                stuff.push({
                    nomenclature: {id: this.stuff[k].nomenclature.id},
                    count: this.stuff[k].count
                });
            });

            tasksLink.map(t => {
                t.creatingProductionAct = true;
                this.$store.dispatch('storage_senior_dashboard/updateTask', t);
            });
            this.$forceUpdate();// Небольшой замес с реактивностьб, поэтому так

            this.$http.post(PRODUCTION_ACTS_ENDPOINT, {
                nomenclature: {id: this.tasks[0].nomenclature.id},
                use_proportional_raw_materials: 0,
                start_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                tasks: taskIds,
                shift: {id: this.actualShift.shift.id},
                storage: {id: this.storage.id},
                rawMaterials: stuff,
                items: items,

                with: WITH,
            }, {
                params: {without_loading: true}
            }).then(response => {
                if (tasksLink.length > 1) {
                    this.$store.dispatch('storage_senior_dashboard/loadTasks');
                } else {
                    tasksLink[0].productionActs.push(response.data.data);
                    tasksLink[0].acts.push(response.data.data.id);
                    this.$store.dispatch('storage_senior_dashboard/loadRests');
                }
                this.$emit('close');
            }).catch(response => {
                ErrorHandler.assert(response,  'Ошибка создания акта выпуска '+tasksLink[0].nomenclature.name);
            }).finally(() => {
                tasksLink.map(t => {
                    t.creatingProductionAct = false;
                    this.$store.dispatch('storage_senior_dashboard/updateTask', t);
                });
                this.$forceUpdate();// Небольшой замес с реактивностьб, поэтому так
            })
        },
        updateProductionAct(items, assume_finished = false, force = false) {

            if (this.assertOverProduction(items, force, () => this.updateProductionAct(items, assume_finished, true))) {
                return;
            }

            if (this.assertNegativeItems(items)) {
                return;
            }

            // Важный момент! Так мы сохраняем ссылку на нужную таску в замыкании запроса
            // Решает кейс, когда начали выпуск и тут же переключились на другую таску
            // - после завершения запроса акт подсовывался не туда
            let taskLink = this.tasks[0];
            let productionActLink = taskLink.productionActs[0];

            taskLink.savingProducedCount = true;
            this.$store.dispatch('storage_senior_dashboard/updateSelectedTask', taskLink);
            this.$store.dispatch('storage_senior_dashboard/updateTask', taskLink);
            this.$forceUpdate();// Небольшой замес с реактивностьб, поэтому так

            let endAt = null;
            if (!productionActLink.end_at) {
                if (assume_finished || sumBy(items, i => parseFloat(i.count)) >= this.requiredCount) {
                    endAt = moment().format('YYYY-MM-DD HH:mm:ss');
                }
            }

            this.$http.put(PRODUCTION_ACTS_ENDPOINT + `/${productionActLink.id}/produce`, {
                items: items,
                end_at: endAt,
                assume_finished: assume_finished ? 1 : 0,
                use_proportional_raw_materials: !!productionActLink.use_proportional_raw_materials ? 1 : 0,

                with: WITH,
            }, {
                params: {without_loading: true}
            }).then(response => {
                productionActLink.items = response.data.data.items;
                productionActLink.end_at = response.data.data.end_at;
                productionActLink.produced_lot = response.data.data.produced_lot;
                productionActLink.assume_finished = response.data.data.assume_finished;
                productionActLink.materialTransferActs = response.data.data.materialTransferActs;
                this.$bvToast.toast('Успешно сохранено', {variant: 'success'});
                this.$emit('close');
            }).catch(response => {
                ErrorHandler.assert(response, 'Ошибка зполнения выполненного кол-ва '+taskLink.nomenclature.name);
            }).finally(() => {
                taskLink.savingProducedCount = false;
                this.$store.dispatch('storage_senior_dashboard/updateSelectedTask', taskLink);
                this.$store.dispatch('storage_senior_dashboard/updateTask', taskLink);
                this.$store.dispatch('storage_senior_dashboard/loadRests');
                this.$forceUpdate();// Небольшой замес с реактивностьб, поэтому так
            });
        },
        loadWorkingUsers() {
            this.workingUsersLoading = true
            return this.$http
                .get(SALARY_ENDPOINT + "/timesheet/working-users", {
                    params: {
                        actual_shift_id: this.relevantShift.id,
                        storage_id: this.storage.id,
                        without_loading: true
                    }
                })
                .then(response => response.data.data)
                .then(workingUsers => {
                    this.workingUsers = workingUsers
                    const currentUser = this.$auth.user()
                    currentUser.actual_shift_id = this.relevantShift.id
                    const foundUser = this.workingUsers.find(
                        workingUser => workingUser.id === currentUser.id
                    )
                    if (!foundUser) {
                        this.workingUsers.push(currentUser)
                        this.workingUsers.sort(
                            (a, b) => (a.fullname ? a.fullname : a.username).localeCompare(b.fullname ? b.fullname : b.username)
                        )
                    }
                })
                .catch(error => {
                    console.error(error)
                    this.$toast.error("Не удалось получить список пользователей")
                })
                .finally(() => {
                    this.workingUsersLoading = false
                })
        },
        loadRelevantShift() {
            this.relevantShift = this.actualShift;
            return this.$http.get(PRODUCTION_SHIFT_ENDPOINT + '/current', {
                params: {
                    storage_id: this.storage.id,
                    without_loading: true,
                }
            })
            .then(response => {
                this.relevantShift = response.data;
            }).catch(() => {
                this.$toast.error('Не удалось загрузить текущую смену');
            });
        },
        assertOverProduction(items, force, continueCallback) {
            // Суммируем только плановые цифры для получения корректного процента перевыпуска
            const producedCount = items.reduce((accumulator, value) => accumulator + parseFloat(value.plan_count), 0);
            let overproducedPercent = parseFloat(((producedCount - this.requiredCount) / this.requiredCount * 100).toFixed(0));
            if (overproducedPercent > ALLOWED_OVERPRODUCTION_PERCENT && !force) {
                this.$modal.show(OverproductionConfirm, {
                    overproducedPercent: overproducedPercent,
                    continueCallback
                }, this.productionActParams.modal);
                return true;
            }

            return false;
        },
        assertNegativeItems(items) {
            let result = false;
            items.map(i => {
                if (i.count < 0 || i.plan_count < 0) {
                    result = true;
                }
            });

            if (result) {
                this.$toast.error('Значение должно быть больше нуля');
            }

            return result;
        },
        isSavingProducedCount() {
            // Небольшой замес с реактивностью, поэтому так
            let result = false;
            this.tasks.map(t => {
                if (!!t.savingProducedCount) {
                    result = true;
                }
            });

            return result;
        },
        isCreatingProductionAct() {
            // Небольшой замес с реактивностью, поэтому так
            let result = false;
            this.tasks.map(t => {
                if (!!t.creatingProductionAct) {
                    result = true;
                }
            });

            return result;
        },
        isItemDisabled(item) {
            return !!item.blocked;
        },
        isActDisabled() {
            if (this.tasks[0].productionActs.length) {
                return this.tasks[0].productionActs[0].blocked;
            }

            return false;
        }
    }
}
